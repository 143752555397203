import React, { Component, useEffect } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { useIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { Field, Form as FinalForm } from 'react-final-form';
import * as validators from '../../util/validators';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import {
  isAnyFilterActive,
  isMainSearchTypeKeywords,
  isOriginInUse,
  getQueryParamNames,
} from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import {
  Button,
  H3,
  H5,
  Heading,
  Icons,
  Modal,
  ModalInMobile,
  Page,
  FieldTextInput,
  FieldCheckbox,
  Form,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import { fetchListings, setActiveListing } from './SearchPage.duck';
import {
  groupListingFieldConfigs,
  initialValues,
  searchParamsPicker,
  validUrlQueryParamsFromProps,
  validFilterParams,
  cleanSearchFromConflictingParams,
  createSearchResultSchema,
  pickListingFieldFilters,
  omitLimitedListingFieldParams,
} from './SearchPage.shared';

import FilterComponent from './FilterComponent';
import SearchMap from './SearchMap/SearchMap';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersSecondary from './SearchFiltersSecondary/SearchFiltersSecondary';
import SearchFiltersPrimary from './SearchFiltersPrimary/SearchFiltersPrimary';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import NoSearchResultsMaybe from './NoSearchResultsMaybe/NoSearchResultsMaybe';

import css from './SearchPage.module.css';
import SectionTopbarFilter from './SectionTopbarFilter/SectionTopbarFilter';
import { withViewport } from '../../util/uiHelpers';
import { extendBoundsByMiles, isArrayLength } from '../../util/genericHelpers';
import { addContactSendgrid } from '../../ducks/global.duck';
import { onTriggerError, onTriggerSuccess } from '../../util/toast';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { getMarketingEventReminder } from '../../util/dataExtractors';
import { StatusCode } from '../../util/enums';
import { DEFAULT_MILE } from '../../util/constants';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: false,
      isMobileModalOpen: false,
      isMapToggled: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      isSecondaryFiltersOpen: false,
      notifyModalOpen: false,
      userClosedModal: false,
      currentSearchCycle: Date.now(),
      hasExtendedBoundsFetched: false,
    };

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    // Filter functions
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
    this.onToggleMap = this.onToggleMap.bind(this);

    this.openNotifyModal = this.openNotifyModal.bind(this);
    this.closeNotifyModal = this.closeNotifyModal.bind(this);
    this.handleNotifySubmit = this.handleNotifySubmit.bind(this);
  }

  fetchListingsWithExtendedBounds(extendedBounds) {
    const { history, location, config, routeConfiguration } = this.props;

    const { address, mapSearch, ...rest } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const originMaybe = isOriginInUse(config) ? { origin: rest.origin } : {};

    const searchParams = {
      address,
      ...originMaybe,
      bounds: extendedBounds,
      mapSearch: true,
    };

    // Update state to indicate extended bounds fetch has occurred
    this.setState(
      {
        hasExtendedBoundsFetched: true,
        notifyModalOpen: false,
        userClosedModal: false,
      },
      () => {
        history.push(
          createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams)
        );
      }
    );
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = this.props.routeConfiguration;
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)

    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, config } = this.props;
      const { listingFields: listingFieldsConfig } = config?.listing || {};
      const { defaultFilters: defaultFiltersConfig } = config?.search || {};
      const listingCategories = config.categoryConfiguration.categories;
      const filterConfigs = {
        listingFieldsConfig,
        defaultFiltersConfig,
        listingCategories,
      };

      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      const originMaybe = isOriginInUse(this.props.config) ? { origin: viewportCenter } : {};
      const dropNonFilterParams = false;

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, filterConfigs, dropNonFilterParams),
      };

      const searchCycleId = Date.now();

      // Reset state for a new search cycle
      this.setState({
        currentSearchCycle: searchCycleId,
        notifyModalOpen: false,
        hasExtendedBoundsFetched: false, // Reset for new search
      });

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  openNotifyModal() {
    this.setState({ notifyModalOpen: true, userClosedModal: false });
  }

  closeNotifyModal() {
    Cookies.set('marketingEventReminder', true);
    this.setState({ notifyModalOpen: false, userClosedModal: true });
  }

  handleNotifySubmit = values => {
    const { onAddContactSendgrid, onUpdateProfile, currentUser } = this.props;

    if (values.email) {
      const payload = {
        email: values.email,
        agreement: values?.isCheckboxChecked,
      };

      onAddContactSendgrid(payload)
        .then(response => {
          if (response?.statusCode === StatusCode?.SUCCESS) {
            onTriggerSuccess('Notify me request successfully sent');
            this.closeNotifyModal();

            if (currentUser?.id) {
              onUpdateProfile({
                protectedData: { marketingEventReminder: true },
              });
            } else {
              Cookies.set('marketingEventReminder', true);
            }
          } else {
            const errorMessage = response?.message || 'Failed to send request';
            onTriggerError(errorMessage);
          }
        })
        .catch(error => {
          // Handle API errors
          const errorMessage =
            error?.response?.data?.message || 'Something went wrong. Please try again.';
          onTriggerError(errorMessage);
        });
    }
  };

  componentDidUpdate(prevProps) {
    const { searchInProgress, listings, location, currentUser } = this.props;
    const marketingEventReminder = currentUser?.id
      ? getMarketingEventReminder(currentUser)
      : Cookies.get('marketingEventReminder');

    const { searchParamsInURL } = searchParamsPicker(
      location.search,
      this.props.searchParams,
      {
        listingFieldsConfig: this.props.config?.listing?.listingFields,
        defaultFiltersConfig: this.props.config?.search?.defaultFilters,
        listingCategories: this.props.config.categoryConfiguration.categories,
      },
      this.props.config?.search?.sortConfig,
      isOriginInUse(this.props.config)
    );

    const { bounds } = searchParamsInURL || {};

    if (!searchInProgress && !marketingEventReminder && this.state.currentSearchCycle) {
      // Initial fetch completed with 0 listings
      if (
        !isArrayLength(listings.length) &&
        prevProps.searchInProgress &&
        !this.state.hasExtendedBoundsFetched
      ) {
        console.log('Initial fetch returned 0 listings, extending bounds...');
        const extendedBounds = extendBoundsByMiles(bounds, DEFAULT_MILE);
        if (extendedBounds) {
          this.fetchListingsWithExtendedBounds(extendedBounds, this.state.currentSearchCycle);
        }
      }

      // Check after any fetch (initial or extended) if listings are still 0
      if (
        !isArrayLength(this.props.listings) && // listings.length === 0
        prevProps.searchInProgress && // Fetch just completed
        !this.state.notifyModalOpen && // Modal isn’t already open
        this.state.hasExtendedBoundsFetched
      ) {
        console.log('Fetch returned 0 listings, opening modal...');
        this.openNotifyModal();
      }
    }
  }

  componentWillUnmount() {
    console.log('Component unmounting, resetting state...');
    this.setState({
      currentSearchCycle: null,
      hasExtendedBoundsFetched: false,
    });
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  onToggleMap() {
    this.setState({ isMapToggled: !this.state.isMapToggled });
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    const listingCategories = config.categoryConfiguration.categories;
    const filterConfigs = {
      listingFieldsConfig,
      defaultFiltersConfig,
      listingCategories,
    };

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(searchParams, filterConfigs, sortConfig);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, {}));
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    const listingCategories = config.categoryConfiguration.categories;
    const filterConfigs = {
      listingFieldsConfig,
      defaultFiltersConfig,
      listingCategories,
    };

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: omitLimitedListingFieldParams(
            {
              ...mergedQueryParams,
              ...updatedURLParams,
              ...keywordsMaybe,
              address,
              bounds,
            },
            filterConfigs
          ),
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(searchParams, filterConfigs, sortConfig);
          history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, routeConfiguration } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  render() {
    const {
      intl,
      listings,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      routeConfiguration,
      onFetchListings,
      config,
      viewport,
      history,
      addContactLoading,
      currentUser,
    } = this.props;

    const { listingFields } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    const { notifyModalOpen } = this.state;

    const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);
    const marketplaceCurrency = config.currency;
    const categoryConfiguration = config.categoryConfiguration;
    const listingCategories = categoryConfiguration.categories;
    const listingFieldsConfig = pickListingFieldFilters({
      listingFields,
      locationSearch: location.search,
      categoryConfiguration,
    });
    const filterConfigs = {
      listingFieldsConfig,
      defaultFiltersConfig,
      listingCategories,
    };

    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
    const { searchParamsAreInSync, urlQueryParams, searchParamsInURL } = searchParamsPicker(
      location.search,
      searchParams,
      filterConfigs,
      sortConfig,
      isOriginInUse(config)
    );

    const validQueryParams = urlQueryParams;

    const isWindowDefined = typeof window !== 'undefined';
    // const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const isMobileLayout = viewport.width > 0 && viewport.width < 768;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const builtInPrimaryFilters = defaultFiltersConfig.filter(f =>
      ['categoryLevel'].includes(f.key)
    );
    const builtInFilters = isKeywordSearch
      ? defaultFiltersConfig.filter(f => !['keywords', 'categoryLevel'].includes(f.key))
      : defaultFiltersConfig.filter(f => !['categoryLevel'].includes(f.key));
    const [customPrimaryFilters, customSecondaryFilters] = groupListingFieldConfigs(
      listingFieldsConfig,
      activeListingTypes
    );
    const extraSliderFilter = [
      {
        key: 'pub_maxBoatSize',
        schemaType: 'pub_maxBoatSize',
        label: 'Boat size (ft)',
        min: 1,
        max: 200,
        step: 1,
      },
      {
        key: 'pub_waterDepth',
        schemaType: 'pub_waterDepth',
        label: 'Water Depth (ft)',
        min: 1,
        max: 200,
        step: 1,
      },
    ];
    const availablePrimaryFilters = [
      ...customPrimaryFilters,
      ...defaultFiltersConfig,
      ...extraSliderFilter,
    ];

    const availableFilters = [
      ...builtInPrimaryFilters,
      ...customPrimaryFilters,
      ...builtInFilters,
      ...customSecondaryFilters,
      ...extraSliderFilter,
    ];

    const hasSecondaryFilters = !!(customSecondaryFilters && customSecondaryFilters.length > 0);

    // Selected aka active filters
    const selectedFilters = validQueryParams;
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;
    const isValidDatesFilter =
      searchParamsInURL.dates == null ||
      (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(validQueryParams, {
          listingFieldsConfig: customSecondaryFilters,
          defaultFiltersConfig: [],
          listingCategories,
        })
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;

    const isSecondaryFiltersOpen = !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
          isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
          toggleSecondaryFiltersOpen: isOpen => {
            this.setState({ isSecondaryFiltersOpen: isOpen, currentQueryParams: {} });
          },
          selectedSecondaryFiltersCount,
        }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo
        ? pagination.totalItems
        : pagination?.paginationUnsupported
        ? listings.length
        : 0;
    const listingsAreLoaded =
      !searchInProgress &&
      searchParamsAreInSync &&
      !!(hasPaginationInfo || pagination?.paginationUnsupported);

    const conflictingFilterActive = isAnyFilterActive(
      sortConfig.conflictingFilters,
      validQueryParams,
      filterConfigs
    );
    const sortBy = mode => {
      return sortConfig.active ? (
        <SortBy
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
          selectedFilters={selectedFilters}
          onSelect={this.handleSortBy}
          showAsPopup
          mode={mode}
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };
    const noResultsInfo = (
      <NoSearchResultsMaybe
        listingsAreLoaded={listingsAreLoaded}
        totalItems={totalItems}
        location={location}
        resetAll={this.resetAll}
      />
    );

    const { bounds, origin } = searchParamsInURL || {};
    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config
    );

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <TopbarContainer
          rootClassName={topbarClasses}
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
        />

        <div className={classNames(this.state.isMapToggled ? css.toggleMap : null, css.container)}>
          {!isMobileLayout && (
            <div className={css.searchPageFilters}>
              <div className={css.middleFilters}>
                <SearchFiltersPrimary isSearchPageFilter={true} {...propsForSecondaryFiltersToggle}>
                  {availablePrimaryFilters.map(config => {
                    return (
                      <FilterComponent
                        key={`SearchFiltersPrimary.${config.key}`}
                        idPrefix="SearchFiltersPrimary"
                        config={config}
                        marketplaceCurrency={marketplaceCurrency}
                        urlQueryParams={validQueryParams}
                        initialValues={initialValues(this.props, this.state.currentQueryParams)}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        intl={intl}
                        showAsPopup
                        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                      />
                    );
                  })}
                </SearchFiltersPrimary>
              </div>{' '}
              <SectionTopbarFilter
                isSearchPageFilter={true}
                intl={intl}
                viewport={viewport}
                history={history}
              />
              <div className={css.toggleMap} onClick={this.onToggleMap}>
                {this.state.isMapToggled ? <Icons name="map" /> : <Icons name="grid" />}
              </div>
            </div>
          )}
          <div className={css.searchMainContainer}>
            <div className={css.searchResultContainer}>
              {/* <SectionTopbarFilter
              isSearchPageFilter={true}
              intl={intl}
              viewport={viewport}
              history={history}
            /> */}
              <SearchFiltersMobile
                className={css.searchFiltersMobileMap}
                urlQueryParams={validQueryParams}
                sortByComponent={sortBy('mobile')}
                listingsAreLoaded={listingsAreLoaded}
                resultsCount={totalItems}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                onMapIconClick={() => this.setState({ isSearchMapOpenOnMobile: true })}
                onManageDisableScrolling={onManageDisableScrolling}
                onOpenModal={this.onOpenMobileModal}
                onCloseModal={this.onCloseMobileModal}
                resetAll={this.resetAll}
                selectedFiltersCount={selectedFiltersCountForMobile}
                noResultsInfo={noResultsInfo}
                isMapVariant
              >
                {availableFilters.map(config => {
                  return (
                    <FilterComponent
                      key={`SearchFiltersMobile.${config.key}`}
                      idPrefix="SearchFiltersMobile"
                      config={config}
                      marketplaceCurrency={marketplaceCurrency}
                      urlQueryParams={validQueryParams}
                      initialValues={initialValues(this.props, this.state.currentQueryParams)}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      intl={intl}
                      liveEdit
                      showAsPopup={false}
                    />
                  );
                })}
              </SearchFiltersMobile>
              <MainPanelHeader
                className={css.mainPanelMapVariant}
                sortByComponent={sortBy('desktop')}
                isSortByActive={sortConfig.active}
                listingsAreLoaded={listingsAreLoaded}
                resultsCount={totalItems}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                noResultsInfo={noResultsInfo}
                onToggleMap={this.onToggleMap}
                isMapToggled={this.state.isMapToggled}
              >
                <div className={css.searchOptions}>
                  <div className={css.searchResultSummary}>
                    <span className={css.resultsFound}>
                      {searchInProgress ? (
                        <FormattedMessage id="MainPanelHeader.loadingResults" />
                      ) : (
                        <div className={css.totalCount}>
                          <FormattedMessage
                            id="MainPanelHeader.foundResults"
                            values={{ count: totalItems }}
                          />
                        </div>
                      )}
                    </span>
                    {sortConfig.active ? (
                      <div className={css.sortyByWrapper}>{sortBy('desktop')}</div>
                    ) : null}
                  </div>
                </div>
              </MainPanelHeader>
              {isSecondaryFiltersOpen ? (
                <div className={classNames(css.searchFiltersPanel)}>
                  <SearchFiltersSecondary
                    urlQueryParams={validQueryParams}
                    listingsAreLoaded={listingsAreLoaded}
                    applyFilters={this.applyFilters}
                    cancelFilters={this.cancelFilters}
                    resetAll={this.resetAll}
                    onClosePanel={() => this.setState({ isSecondaryFiltersOpen: false })}
                  >
                    {customSecondaryFilters.map(config => {
                      return (
                        <FilterComponent
                          key={`SearchFiltersSecondary.${config.key}`}
                          idPrefix="SearchFiltersSecondary"
                          config={config}
                          marketplaceCurrency={marketplaceCurrency}
                          urlQueryParams={validQueryParams}
                          initialValues={initialValues(this.props, this.state.currentQueryParams)}
                          getHandleChangedValueFn={this.getHandleChangedValueFn}
                          intl={intl}
                          showAsPopup={false}
                        />
                      );
                    })}
                  </SearchFiltersSecondary>
                </div>
              ) : (
                <div
                  className={classNames(css.listingsForMapVariant, {
                    [css.newSearchInProgress]: !(listingsAreLoaded || searchListingsError),
                  })}
                >
                  {searchListingsError ? (
                    <H3 className={css.error}>
                      <FormattedMessage id="SearchPage.searchError" />
                    </H3>
                  ) : null}
                  {!isValidDatesFilter ? (
                    <H5>
                      <FormattedMessage id="SearchPage.invalidDatesFilter" />
                    </H5>
                  ) : null}
                  <SearchResultsPanel
                    className={css.searchListingsPanel}
                    listings={listings}
                    pagination={listingsAreLoaded ? pagination : null}
                    search={parse(location.search)}
                    setActiveListing={onActivateListing}
                    isMapVariant
                    isSearchPageFilter={this.state.isMapToggled}
                  />
                </div>
              )}
            </div>
            {this.state.isMapToggled ? null : (
              <ModalInMobile
                className={css.mapPanel}
                id="SearchPage.map"
                isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                onManageDisableScrolling={onManageDisableScrolling}
                isSearchMapModal={true}
              >
                <div className={css.mapWrapper} data-testid="searchMapContainer">
                  {shouldShowSearchMap && !this.state.isMapToggled ? (
                    <SearchMap
                      reusableContainerClassName={css.map}
                      activeListingId={activeListingId}
                      bounds={bounds}
                      center={origin}
                      isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                      location={location}
                      listings={listings || []}
                      onMapMoveEnd={this.onMapMoveEnd}
                      onCloseAsModal={() => {
                        onManageDisableScrolling('SearchPage.map', false);
                      }}
                      messages={intl.messages}
                      onFetchListings={onFetchListings}
                    />
                  ) : null}
                </div>
              </ModalInMobile>
            )}
          </div>

          <Modal
            id="SearchPageWithMap.notifyMe"
            isOpen={notifyModalOpen}
            onClose={this.closeNotifyModal}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <FinalForm
              onSubmit={this.handleNotifySubmit}
              initialValues={{ email: currentUser?.attributes?.email || '' }}
              render={({ handleSubmit, values }) => {
                const emailRequired = validators.required(
                  intl.formatMessage({ id: 'SearchPageWithMap.Modal.emailRequired' })
                );
                const emailValid = validators.emailFormatValid(
                  intl.formatMessage({ id: 'SearchPageWithMap.Modal.emailInvalid' })
                );

                return (
                  <Form className={css.comingSoonModal} onSubmit={handleSubmit}>
                    <div className={css.comingSoonHead}>
                      <Heading as="h4">
                        {intl.formatMessage({ id: 'SearchPageWithMap.Modal.heading' })}
                      </Heading>
                      <p>{intl.formatMessage({ id: 'SearchPageWithMap.Modal.description1' })}</p>
                      <p>{intl.formatMessage({ id: 'SearchPageWithMap.Modal.description2' })}</p>
                      <p>{intl.formatMessage({ id: 'SearchPageWithMap.Modal.description3' })}</p>
                    </div>
                    <div className={css.comingSoonEmail}>
                      {/* Email Field */}
                      <FieldTextInput
                        type="email"
                        id="email"
                        name="email"
                        autoComplete="email"
                        label={intl.formatMessage({ id: 'SearchPageWithMap.Modal.emailLabel' })}
                        placeholder={intl.formatMessage({
                          id: 'SearchPageWithMap.Modal.emailPlaceholder',
                        })}
                        validate={validators.composeValidators(emailRequired, emailValid)}
                      />
                    </div>
                    <div className={css.checkbox}>
                      {/* Checkbox using FieldCheckbox */}
                      <FieldCheckbox
                        id="isCheckboxChecked"
                        className={css.deliveryCheckbox}
                        name="isCheckboxChecked"
                        label={intl.formatMessage({ id: 'SearchPageWithMap.Modal.checkboxText' })}
                      />
                    </div>
                    {/* Submit Button */}
                    <Button type="submit" disabled={addContactLoading || !values.email}>
                      {addContactLoading
                        ? intl.formatMessage({ id: 'SearchPageWithMap.Modal.notifyMeBtn1' })
                        : intl.formatMessage({ id: 'SearchPageWithMap.Modal.notifyMeBtn2' })}
                    </Button>
                  </Form>
                );
              }}
            />
          </Modal>
        </div>
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  activeListingId: null,
  addContactLoading: false,
};

SearchPageComponent.propTypes = {
  listings: array,
  onActivateListing: func.isRequired,
  onAddContactSendgrid: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <SearchPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  } = state.SearchPage;

  const { addContactLoading, addContactError } = state.global;

  const listings = getListingsById(state, currentPageResultIds);

  const { currentUser } = state.user;

  return {
    listings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    addContactLoading,
    addContactError,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onFetchListings: params => dispatch(fetchListings(params)),
  onAddContactSendgrid: body => dispatch(addContactSendgrid(body)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withViewport,
  connect(mapStateToProps, mapDispatchToProps)
)(EnhancedSearchPage);

export default SearchPage;
